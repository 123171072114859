<template>
  <div class="customers">
    <v-progress-linear
      :active="loading"
      :indeterminate="loading"
      absolute
      top
      color="#006FC0"
    ></v-progress-linear>
    <div class="content">
      <h3>Новые пользователи чат-бота</h3>
      <div class="filters">
        <button
          class="filters__filter"
          :class="{ active: index == pickedFilter }"
          v-for="(filter, index) in userFilters"
          @click="pickedFilter = index"
          :key="index"
        >
          <span>{{ filter }}</span>
        </button>
      </div>
      <!-- таблица новые колво -->
      <div class="table-wrapper" v-show="newUsers">
        <v-simple-table class="table" fixed-header>
          <template v-slot:default>
            <thead>
              <tr>
                <th style="text-align: center">Канал</th>
                <th style="text-align: center">Количество</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in newUsers" :key="item.id">
                <td style="text-align: center">{{ item.channel }}</td>
                <td style="text-align: center">{{ item.count }}</td>
              </tr>
              <tr>
                <td style="text-align: center">Итого</td>
                <td style="text-align: center">
                  {{ newUsersAmount }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>
      <h3>Пользователи чат-бота</h3>
      <!-- таблица общее колво -->
      <div class="table-wrapper" v-show="totalUsers">
        <v-simple-table class="table" fixed-header>
          <template v-slot:default>
            <thead>
              <tr>
                <th style="text-align: center">Канал</th>
                <th style="text-align: center">Количество</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in totalUsers" :key="item.id">
                <td style="text-align: center">{{ item.channel }}</td>
                <td style="text-align: center">{{ item.count }}</td>
              </tr>
              <tr>
                <td style="text-align: center">Итого</td>
                <td style="text-align: center">
                  {{ totalUsersAmount }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>
      <!-- таблица вопросы -->
      <h3 class="title-popular">Популярные вопросы</h3>
      <div class="table-wrapper" v-show="blocks.length > 0">
        <v-simple-table class="table" fixed-header>
          <template v-slot:default>
            <thead>
              <tr>
                <th
                  class="sticky-header text-left"
                  :class="scrollPosition >= 385 ? 'shadow' : null"
                >
                  №
                </th>
                <th
                  class="sticky-header text-left"
                  :class="scrollPosition >= 385 ? 'shadow' : null"
                >
                  Вопросы
                </th>
                <th
                  class="sticky-header text-left"
                  :class="scrollPosition >= 385 ? 'shadow' : null"
                >
                  Частота
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in blocks.slice(0, sliceValue)"
                :key="item.id"
              >
                <td>{{ index + 1 }}</td>
                <td>{{ item.tag }}</td>
                <td>{{ item.hits }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>
      <!-- если нет категорий созданных -->
      <!-- <div
        class="noCards"
        v-show="blocks.length <= 0"
      >
        <div class="text">Вопросов нет</div>
      </div> -->
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      blocks: [],
      newUsers: [],
      totalUsers: [],
      newUsersAmount: 0,
      totalUsersAmount: 0,

      userFilters: {
        yesterday: "Вчера",
        today: "Сегодня",
        week: "Неделя",
        month: "Месяц",
      },
      pickedFilter: "yesterday",

      loading: false,
      sliceValue: 50,
      scrollPosition: null,
    };
  },
  methods: {
    updateScroll() {
      this.scrollPosition = window.scrollY;
      /* Если высота окна + высота прокрутки больше или равны высоте всего документа, то  */
      if (
        window.innerHeight + window.scrollY >= document.body.clientHeight &&
        this.statisticsTest.length > this.sliceValue
      ) {
        this.sliceValue += 50;
      }
    },
    compareChannels(a, b) {
      if (a.channel === "-" || b.channel === "-") {
        return -1;
      }
      if (a.channel > b.channel) {
        return 1;
      }
      if (a.channel < b.channel) {
        return -1;
      }
      return 0;
    },
    getBlocks() {
      return new Promise((resolve, reject) => {
        axios
          .get("/stats/blocks")
          .then((resp) => {
            this.blocks = resp.data.sort((a, b) => b.hits - a.hits);
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
            console.log(err);
          });
      });
    },
    getNewUsers() {
      let start_date;
      let end_date;

      switch (this.pickedFilter) {
        case "yesterday": {
          let d = new Date();
          d.setDate(d.getDate() - 1);
          d = d.toISOString().slice(0, 10);
          start_date = d;
          end_date = d;
          break;
        }
        case "today": {
          let d = new Date();
          d = d.toISOString().slice(0, 10);
          start_date = d;
          end_date = d;
          break;
        }
        case "week": {
          let start = new Date();
          let end = new Date();
          start.setDate(start.getDate() - 7);
          start = start.toISOString().slice(0, 10);
          end = end.toISOString().slice(0, 10);
          start_date = start;
          end_date = end;
          break;
        }
        case "month": {
          let start = new Date();
          let end = new Date();
          start.setDate(start.getDate() - 30);
          start = start.toISOString().slice(0, 10);
          end = end.toISOString().slice(0, 10);
          start_date = start;
          end_date = end;
          break;
        }
      }
      return new Promise((resolve, reject) => {
        axios
          .get(`/stats/users/new`, { params: { start_date, end_date } })
          .then((resp) => {
            this.newUsers = resp.data.sort(this.compareChannels);
            this.newUsersAmount = this.newUsers.reduce(
              (sum, current) => sum + current.count,
              0
            );
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
            console.log(err);
          });
      });
    },
    getTotalUsers() {
      return new Promise((resolve, reject) => {
        axios
          .get(`/stats/users/total`)
          .then((resp) => {
            this.totalUsers = resp.data.sort(this.compareChannels);
            this.totalUsersAmount = this.totalUsers.reduce(
              (sum, current) => sum + current.count,
              0
            );
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
            console.log(err);
          });
      });
    },
  },
  watch: {
    pickedFilter: function() {
      this.getNewUsers();
    },
  },
  mounted() {
    window.addEventListener("scroll", this.updateScroll);
  },
  destroy() {
    window.removeEventListener("scroll", this.updateScroll);
  },
  created() {
    this.getBlocks();
    this.getNewUsers();
    this.getTotalUsers();
  },
};
</script>

<style lang="scss" scoped>
.customers {
  position: relative;
  height: 100%;
  .content {
    padding: 130px 365px 118px 365px;
    height: 100%;
    display: flex;
    flex-direction: column;
    h3 {
      margin-bottom: 24px;
      text-transform: uppercase;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: #1c1c1c;
    }
  }
}
// заголовок
.title-popular {
  // margin-top: 42px;
}
.v-data-table .sticky-header {
  position: sticky;
  top: 70px;
  background: white;
  z-index: 2;
  &.shadow {
    box-shadow: 3px 5px 4px 0px rgba(0, 0, 0, 0.2) !important;
  }

  // border-bottom: thin solid rgba(0, 0, 0, 0.2);
}
.table {
  &-wrapper {
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
    background: white;
    border-radius: 5px;
    margin-bottom: 75px;
  }
  thead {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  th {
    padding: 13px 23px !important;
    text-transform: uppercase;
  }
  th:nth-child(1) {
    border-top-left-radius: 5px;
  }
  th:nth-child(6) {
    border-top-right-radius: 5px;
  }
  td {
    padding: 0 23px !important;
  }
  .actions {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    .btn {
      min-width: 24px !important;
      width: 24px !important;
      height: 24px !important;
      &:not(:last-child) {
        margin-right: 10px;
      }
    }
  }
}
.noCards {
  z-index: 10;
  position: absolute;
  width: 100%;
  background: rgba(108, 108, 108, 0.15);
  height: 100%;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .text {
    max-width: 408px;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
    color: #333333;
    margin-bottom: 32px;
  }
}
.btn {
  align-self: flex-end;
  width: 167px;
  height: 47px !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  &__text {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
    color: #006fc0 !important;
    margin-left: 18px;
    margin-right: 11px;
  }
}
.filters {
  margin: 0px 0 14px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  &__filter {
    padding: 0 !important;
    font-size: 16px;
    line-height: 20px;
    color: rgba(28, 28, 28, 0.87);
    margin-right: 16px;
    &.active {
      color: #006fc0 !important;
    }
    &:hover.active {
      color: #4b99d2 !important;
    }
    &:hover {
      color: #4b99d2 !important;
      background: transparent;
    }
  }
  .datepicker-wrapper {
    // width: 120px;
  }
}
</style>
